import axios from 'axios'
import { cloneDeep, get, isEmpty } from 'lodash'
// import * as retryAxios from 'retry-axios'

class ApiCore {
  constructor(nuxtContext, apiModel, storeStatePath = null, timeout = 30000) {
    this.nuxt = nuxtContext

    // We allow usage of this.$Api() without passing an apiModel, although it's not recommended
    if (!apiModel) {
      apiModel = new this.nuxt.$ApiModel()
    }

    this.storeStatePath = storeStatePath
    this.isImmutable = !!storeStatePath

    // Are we making state immutible for Vuex or updating the referenced apiModel directly
    this.state = this.isImmutable ? cloneDeep(apiModel) : apiModel

    this.isThirdPartyApi = false
    this.isDebugEnabled = false
    this.organisationOverrideId = null
    this.timeout = timeout
    this.axios = axios.create({ paramsSerializer: { indexes: null } })

    // retryAxios.attach(this.axios)

    this.hooks = {
      transformRequest: null,
      transformResponse: null
    }

    if (process.client) {
      this.cancelSource = axios.CancelToken.source()
    }

    // Axios fetch doesn't have defaults for transform
    this.hooks.transformResponse = axios.defaults.transformResponse
    this.hooks.transformRequest = axios.defaults.transformRequest
  }

  useStorePath(storeStatePath) {
    this.storeStatePath = storeStatePath
    this.isImmutable = !!storeStatePath

    // In case null is passed as storeStatePath
    if (this.storeStatePath) {
      this.state = cloneDeep(this.state)
    }

    return this
  }

  setOrganisationOverrideId(organisationId) {
    this.organisationOverrideId = organisationId

    return this
  }

  // Execute the API request
  async send(path, method = 'GET', data = this.state?.model, config) {
    this.isThirdPartyApi = path.includes('http')

    const callDebug = `${method} ${path}`

    const canSaveLogs = !path.includes('/analytics/')

    // Maintenance mode, block all requests
    if (
      this.nuxt.store.getters['app/isMaintenanceModeEnabled'] &&
      !this.nuxt.store.getters['app/isMaintenanceModeBypassed']
    ) {
      this.nuxt.$log.debug('Maitenance mode blocked', callDebug)

      return false
    }

    // Check throttling
    if (config.throttleMs) {
      const timeMethodLastCalledMs = Date.now() - this.state.timeMethodLastCalled[method]

      // Called too often
      if (timeMethodLastCalledMs < config.throttleMs) {
        this.nuxt.$log.debug('Throttling call', callDebug, canSaveLogs)
        return false
      }
    }

    // Don't add to $log if request is going to /log otherwise the log queue will never empty!
    if (this.isDebugEnabled && !path.includes('/system/logs')) {
      this.nuxt.$log.debug(callDebug, null, canSaveLogs)
    }

    if (process.client) {
      this.nuxt.$network.checkConnection()
    }

    this.state.cancel = this.cancelSource.cancel

    // Log our last call for use in throttling
    this.state.timeMethodLastCalled[method] = Date.now()

    const defaultHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    if (config['Content-Type']) {
      defaultHeaders['Content-Type'] = config['Content-type']
    }

    const options = {
      method,
      timeout: this.timeout,
      headers: config.headers ? config.headers : defaultHeaders,
      url: path,
      responseType: 'json',
      // Setting withCredentials allows the API to set httpOnly cookies in the response headers of an XHR request
      // We need to add some safeguards to this and ensure we are only allowing this when contacting our API with /auth/ endpoints
      withCredentials: config.withCredentials && !this.isThirdPartyApi && path.includes('/auth/'),
      isFormData: config.isFormData,
      transformRequest: config.transformRequest || this.hooks.transformRequest || undefined,
      transformResponse: this.hooks.transformResponse || undefined,
      cancelToken: process.client ? this.cancelSource.token : undefined,
      onUploadProgress: progressEvent => {
        this.state.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }
    }

    if (method === 'GET') {
      options.params = data
    } else {
      options.data = data
    }

    // Intentionally not retrying 503s as we want to react quickly to maintenance mode
    options.raxConfig = {
      instance: this.axios,
      httpMethodsToRetry: ['GET', 'OPTIONS', 'HEAD'],
      statusCodesToRetry: [
        [100, 199],
        [429, 429],
        [500, 502]
      ]
      //   onRetryAttempt: retryError => {
      //     // The retry attempt will only occur after the promise is resolved
      //     return new Promise((resolve, reject) => {
      //       // Refresh our token if 403
      //       if (
      //         [403].includes(retryError?.response?.status) &&
      //         !this.nuxt.route.path.includes('/auth') &&
      //         !retryError.request.responseURL.includes('/auth/')
      //       ) {
      //         try {
      //           this.nuxt.store.dispatch('auth/refreshToken').then(() => {
      //             resolve()
      //           })
      //         } catch (error) {
      //           reject(error)
      //         }
      //       } else {
      //         resolve()
      //       }
      //     })
      //   }
    }

    const urlKey = encodeURIComponent(`${path}${JSON.stringify(data)}`)

    this.state.isLoading = true
    this.state.response.status = null
    this.state.response.message = null
    this.state.response.errors = []

    if (config.cache) {
      // Do we have a cache for this urlKey?
      const cachedResponse = this.nuxt.store.getters['apiDataCache/getCachedData'](urlKey)

      // We have a match! Let's immediately set the response to the cached value
      if (!isEmpty(cachedResponse)) {
        this.state.response = cloneDeep(cachedResponse)
        this.state.isLoading = false
      }
    }

    this.updateState()

    const apiResponse = await this.axios(options)
      // FAILED request
      .catch(error => {
        const statusCode = get(error, 'response.status') || 500

        this.state.response.code = statusCode
        this.state.hasError = true

        const ignoreError =
          error.response === undefined || error.code === 'ECONNABORTED' || !error.message || statusCode < 500
        // || this.axios.isCancel(error)

        // Let's not spam Sentry with errors caused by bad internet
        if (ignoreError) {
          if (!path.includes('/system/logs')) {
            this.nuxt.$log.debug(`API ignored error ${path}`, error?.message, canSaveLogs)
          }
        } else {
          this.nuxt.$log.error(`API error ${path}`, error?.message, canSaveLogs)
        }

        this.state.response.status = 'error'

        this.state.response.errors = get(error, 'response.data.errors', [])

        this.updateState()

        const errorMessage =
          error?.response?.data?.message || error?.message || 'Something went wrong, please reload and try again'

        throw new Error(errorMessage)
      })
      .finally(() => {
        this.state.isLoading = false
        this.updateState()

        if (process.client) {
          this.nuxt.$network.checkConnection()
        }
      })

    if (this.isDebugEnabled) {
      this.nuxt.$log.debug({ apiResponse }, null, canSaveLogs)
    }

    const isJsonResponse = apiResponse.headers['content-type']?.includes('application/json')

    if (apiResponse && apiResponse.data) {
      this.state.response.code = apiResponse.status
      this.state.response.status = apiResponse.data.status
      this.state.response.message = apiResponse.data.message

      const hasPagination = isJsonResponse && 'results' in apiResponse.data && 'total' in apiResponse.data

      this.state.response.data = hasPagination ? apiResponse.data.results : apiResponse.data

      this.state.response.pagination = {}

      if (hasPagination) {
        const totalDocuments = apiResponse.data.total
        // Objection.js pagination page is zero based :(
        const currentPage = options.params.page + 1
        const documentsPerPage = options.params.limit
        const lastPage = Math.ceil(totalDocuments / documentsPerPage)

        this.state.response.pagination = {
          currentPage,
          documentsPerPage,
          totalDocuments,
          isVisible: totalDocuments > options.params.limit,
          from: (currentPage - 1) * documentsPerPage + 1,
          to: currentPage * documentsPerPage,
          previousPage: currentPage > 1 ? currentPage - 1 : null,
          nextPage: currentPage < lastPage ? currentPage + 1 : null,
          lastPage
        }
      }

      if (apiResponse.data.filterOptions) {
        this.state.response.filterOptions = apiResponse.data.filterOptions
      }

      if (apiResponse.data.sortOptions) {
        this.state.response.sortOptions = apiResponse.data.sortOptions
      }

      // If we've enabled cache for this request let's save the response
      if (config.cache) {
        this.nuxt.store.commit('apiDataCache/addToCache', {
          urlKey,
          data: cloneDeep(this.state.response)
        })
      }
    } else if (this.state.response.status !== 'error' && !this.isThirdPartyApi) {
      console.warn(
        `Request to ${path} completed, but with no data key in the response`,
        JSON.stringify(apiResponse)
      )
    }

    this.updateState()

    // Support method chaining
    return cloneDeep(this.state)
  }

  updateState() {
    const newState = cloneDeep(this.state)

    // If we provided a vuex path for the state object, let's go mutate it.
    // This is a nasty side effect but it prevents so much Vuex boilerplate it's worth it.
    if (this.storeStatePath) {
      this.nuxt.store.commit('setGlobalState', {
        statePath: this.storeStatePath,
        newState
      })
    }
  }

  enableDebug() {
    this.isDebugEnabled = true

    // Support method chaining
    return this
  }

  get(path, params = undefined, config = {}) {
    return this.send(path, 'GET', params, config)
  }

  post(path, data = undefined, config = {}) {
    return this.send(path, 'POST', data, config)
  }

  postFormData(path, data = undefined, config = {}) {
    config = { ...config, isFormData: true }
    return this.send(path, 'POST', data, config)
  }

  patch(path, data = undefined, config = {}) {
    return this.send(path, 'PATCH', data, config)
  }

  put(path, data = undefined, config = {}) {
    return this.send(path, 'PUT', data, config)
  }

  delete(path, data = undefined, config = {}) {
    return this.send(path, 'DELETE', data, config)
  }

  head(path, data = undefined, config = {}) {
    return this.send(path, 'HEAD', data, config)
  }

  resetResponse() {
    Object.assign(this.state.response, { ...new this.nuxt.$ApiModel().response })
  }

  cancel() {
    this.cancelSource.cancel()
  }

  // This is usually called on first page load to set
  // existing data from a local storage source
  hydrate(data) {
    if (!data) {
      return this
    }

    this.state.response.data = data

    return this
  }
}

export default ApiCore
