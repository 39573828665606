import capitalize from 'lodash.capitalize'
import { Ability, AbilityBuilder } from '@casl/ability'

const resources = [
  'register',
  'profile',
  'dashboard',
  'actionPlan',
  'funding',
  'content',
  'files',
  'surveys',
  'farmMap',
  'sharing',
  'feedback',
  'soilLabResults',
  'myDataOverview',
  'fundingTool',
  'partnerAccountManagers',
  'staffDashboard',
  'staffUsers',
  'staffOrganisations',
  'staffAccessOrganisations',
  'staffTeam',
  'staffFarmMap',
  'staffFarmClone',
  'staffInternalOnly',
  'staffInternalNotes',
  'staffLabResults'
]

export const roleConfig = {
  FARM_READ: {
    value: 'FARM_READ',
    label: 'Read only',
    translationKey: 'roles.farmRead',
    color: 'blue',
    description: 'Has read only access to farm data and reports',
    permissions: function ({ can }, organisationId) {
      can('read', 'profile')
      can('update', 'profile')
      can('manage', 'files')
      can('read', 'dashboard')
      can('read', 'content')
      can('read', 'actionPlan')
      can('read', 'funding')
      can('read', 'farmMap')
      can('read', 'surveys')
      can('read', 'myDataOverview')
      // can('read', 'fundingTool')
    }
  },
  FARM_WRITE: {
    value: 'FARM_WRITE',
    label: 'Write access',
    translationKey: 'roles.farmWrite',
    color: 'yellow',
    description: 'Can upload new data and make recommendations',
    permissions: function ({ can }, organisationId) {
      can('read', 'content', { published: true })
      can('read', 'profile')
      can('update', 'profile')
      can('manage', 'files')
      can('manage', 'dashboard')
      can('manage', 'actionPlan')
      can('read', 'funding')
      can('manage', 'farmMap')
      can('manage', 'surveys')
      can('manage', 'myDataOverview')
      can('manage', 'soilLabResults')
      // can('read', 'fundingTool')
    }
  },
  FARM_ADMIN: {
    value: 'FARM_ADMIN',
    label: 'Account admin',
    translationKey: 'roles.farmAdmin',
    description: 'Can manage everything and share with new users',
    color: 'red',
    permissions: function ({ can }, organisationId) {
      can('read', 'content', { published: true })
      can('read', 'profile')
      can('update', 'profile')
      can('manage', 'files')
      can('manage', 'dashboard')
      can('manage', 'actionPlan')
      can('read', 'funding')
      can('manage', 'sharing')
      can('manage', 'farmMap')
      can('manage', 'surveys')
      can('manage', 'myDataOverview')
      can('manage', 'soilLabResults')
      // can('read', 'fundingTool')
    }
  },
  PARTNER_ACCOUNT_MANAGER: {
    isPartnerRole: true,
    value: 'PARTNER_ACCOUNT_MANAGER',
    label: 'Account manager',
    translationKey: 'roles.partnerAccountManager',
    description: 'Has access to the farms they manage',
    color: 'red',
    permissions: function ({ can }, organisationId) {
      can('read', 'content', { published: true })
      can('read', 'profile')
      can('update', 'profile')
      can('manage', 'files')
      can('manage', 'dashboard')
      can('read', 'actionPlan')
      can('read', 'funding')
      can('manage', 'sharing')
      can('read', 'farmMap')
      can('read', 'partnerAccountManagers')
      can('read', 'myDataOverview')
      can('read', 'soilLabResults')
      // can('read', 'fundingTool')
    }
  },
  PARTNER_DATA_ADMINISTRATOR: {
    isPartnerRole: true,
    value: 'PARTNER_DATA_ADMINISTRATOR',
    label: 'Data administrator',
    translationKey: 'roles.partnerDataAdministrator',
    description: 'Has access to collated data for all farms their organisation manages',
    color: 'red',
    permissions: function ({ can }, organisationId) {
      can('read', 'content', { published: true })
      can('read', 'profile')
      can('update', 'profile')
      can('manage', 'files')
      can('manage', 'dashboard')
      can('read', 'actionPlan')
      can('read', 'funding')
      can('manage', 'sharing')
      can('read', 'farmMap')
      can('read', 'surveys')
      can('read', 'partnerAccountManagers')
      can('read', 'myDataOverview')
      can('read', 'soilLabResults')
      // can('read', 'fundingTool')
    }
  },
  PARTNER_ADMIN: {
    isPartnerRole: true,
    value: 'PARTNER_ADMIN',
    label: 'Admin',
    translationKey: 'roles.partnerAdmin',
    description: 'Can manage partner organisation, employees and which farms they have access to',
    color: 'red',
    permissions: function ({ can }, organisationId) {
      can('read', 'content', { published: true })
      can('read', 'profile')
      can('update', 'profile')
      can('manage', 'files')
      can('manage', 'dashboard')
      can('read', 'actionPlan')
      can('read', 'funding')
      can('manage', 'sharing')
      can('read', 'farmMap')
      can('read', 'surveys')
      can('read', 'partnerAccountManagers')
      can('read', 'myDataOverview')
      can('read', 'soilLabResults')
      // can('read', 'fundingTool')
    }
  },
  STAFF_GUEST_WRITER: {
    isStaffRole: true,
    value: 'STAFF_GUEST_WRITER',
    label: 'Staff guest writer',
    translationKey: 'roles.staffGuestWriter',
    description: 'Third party access to create draft content only. Can edit their own content',
    color: 'green',
    permissions: function ({ can }, organisationId) {
      can('read', 'profile')
      can('update', 'profile')
      can('read', 'content')
      can('manage', 'files')
      can('read', 'content')
      can('create', 'content', { published: false })
      can('update', 'content', { published: false, organisationId })
      can('read', 'staffInternalOnly')
      can('create', 'feedback')
      // can('read', 'fundingTool')
    }
  },
  STAFF_SALES: {
    isStaffRole: true,
    value: 'STAFF_SALES',
    label: 'Staff sales',
    translationKey: 'roles.staffSales',
    description: 'Read only access to users and organisations',
    color: 'green',
    permissions: function ({ can }, organisationId) {
      can('read', 'profile')
      can('update', 'profile')
      can('read', 'staffUsers')
      can('read', 'content')
      can('manage', 'files')
      can('read', 'staffOrganisations')
      can('read', 'staffDashboard')
      can('read', 'staffInternalOnly')
      can('create', 'feedback')
      can('read', 'myDataOverview')
      can('read', 'staffInternalNotes')
      // can('read', 'fundingTool')
    }
  },
  STAFF_CUSTOMER_SERVICE: {
    isStaffRole: true,
    value: 'STAFF_CUSTOMER_SERVICE',
    label: 'Staff customer service',
    translationKey: 'roles.staffCustomerService',
    description: 'Write access to users and organisations.',
    color: 'yellow',
    permissions: function ({ can }, organisationId) {
      can('read', 'profile')
      can('update', 'profile')
      can('manage', 'files')
      can('read', 'staffUsers')
      can('update', 'staffUsers')
      can('manage', 'content')
      can('read', 'staffOrganisations')
      can('update', 'staffOrganisations')
      can('read', 'staffDashboard')
      can('read', 'staffInternalOnly')
      can('create', 'feedback')
      can('read', 'myDataOverview')
      can('manage', 'staffInternalNotes')
      can('manage', 'soilLabResults')
      // can('read', 'fundingTool')
    }
  },
  STAFF_ADVISOR: {
    isStaffRole: true,
    value: 'STAFF_ADVISOR',
    label: 'Staff advisor',
    translationKey: 'roles.staffAdvisor',
    description:
      'Read access to staff accessible organisations. Write access to surveys, field sampling, lab results. Read access to farm maps, funding and action plans',
    color: 'yellow',
    permissions: function ({ can }, organisationId) {
      can('read', 'profile')
      can('update', 'profile')
      can('read', 'dashboard')
      can('read', 'content')
      can('manage', 'actionPlan')
      can('manage', 'files')
      can('read', 'funding')
      can('manage', 'surveys')
      can('manage', 'farmMap')
      can('read', 'staffAccessOrganisations')
      can('read', 'staffInternalOnly')
      can('create', 'feedback')
      can('manage', 'myDataOverview')
      can('manage', 'staffInternalNotes')
      can('manage', 'soilLabResults')
      // can('read', 'fundingTool')
    }
  },
  STAFF_TECHNICIAN: {
    isStaffRole: true,
    value: 'STAFF_TECHNICIAN',
    label: 'Staff technician',
    translationKey: 'roles.staffTechnician',
    description:
      'Read access to staff accessible organisations. Write access to field sampling. Read access to farm maps',
    color: 'yellow',
    permissions: function ({ can }, organisationId) {
      can('read', 'profile')
      can('update', 'profile')
      can('manage', 'farmMap')
      can('read', 'content')
      can('manage', 'files')
      can('manage', 'surveys')
      can('read', 'staffAccessOrganisations')
      can('read', 'staffInternalOnly')
      can('create', 'feedback')
      can('manage', 'staffInternalNotes')
      can('manage', 'soilLabResults')
      // can('read', 'fundingTool')
    }
  },
  STAFF_ADMIN: {
    isStaffRole: true,
    value: 'STAFF_ADMIN',
    label: 'Staff admin',
    translationKey: 'roles.staffAdmin',
    description: 'Full access to everything execpt impersonating other users',
    color: 'red',
    permissions: function ({ can }, organisationId) {
      resources.forEach(resource => {
        can('manage', resource)
      })
    }
  },
  STAFF_SUPER_ADMIN: {
    isStaffRole: true,
    value: 'STAFF_SUPER_ADMIN',
    label: 'Staff super admin',
    translationKey: 'roles.staffSuperAdmin',
    description: 'Full access to everything',
    color: 'red',
    permissions: function ({ can }, organisationId) {
      resources.forEach(resource => {
        can('manage', resource)
      })

      can('manage', 'staffUserImpersonate')
    }
  }
}

export function loadAbilityFromJSON(permissions = []) {
  return new Ability(permissions)
}

export function defineAbilityFor(role, organisationId) {
  if (role) {
    return new Ability(defineRulesFor(role, organisationId))
  }

  // Guest user
  return new Ability(defineRulesFor())
}

export function defineRulesFor(role, organisationId) {
  const builder = new AbilityBuilder(Ability)
  const { can } = builder

  if (role) {
    roleConfig[role].permissions(builder, organisationId)
  } else {
    can('create', 'register')
  }

  return builder.rules
}

export const roles = Object.fromEntries(
  Object.entries(roleConfig).map(([roleKey, config]) => [roleKey, config.value])
)

export const staffRoles = Object.keys(roles).filter(roleKey => roleConfig[roleKey].isStaffRole)

export const standardOrgRoles = Object.keys(roles).filter(roleKey => !roleConfig[roleKey].isStaffRole)

export const findRoleConfigByValue = value => {
  return Object.values(roleConfig).find(role => role.value === value)
}

export function getRoleOptions({ isStaff = false, isPartner = false } = {}) {
  return Object.keys(roleConfig)
    .filter(roleKey => {
      if (isStaff) {
        return roleConfig[roleKey].isStaffRole
      }

      if (isPartner) {
        return roleConfig[roleKey].isPartnerRole
      }

      return !roleConfig[roleKey].isStaffRole && !roleConfig[roleKey].isPartnerRole
    })
    .map(roleKey => ({
      value: roleConfig[roleKey].value,
      // TODO: Return translation key for FE instead
      label: roleConfig[roleKey].label,
      description: roleConfig[roleKey].description
    }))
}
