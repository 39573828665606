import distance from '@turf/distance'
import { locationTypes } from 'exchange-common/geo/locationTypes'

import { isNative } from '~/plugins/native/capacitor'

// Control geolocation tracking
export default async function ({ store, $log, $config, $date }, inject) {
  const locationTracking = {
    watchId: null,
    lastLngLat: []
  }

  if (isNative) {
    const geoLocationPlugin = await import('@capacitor/geolocation')
    const geoLocation = geoLocationPlugin.Geolocation

    locationTracking.start = async () => {
      try {
        if (locationTracking.watchId) {
          $log.debug('Location tracking already started')
          return false
        }

        const state = await geoLocation.checkPermissions()

        // Clear any existing watchers
        await locationTracking.stop()

        $log.debug('Location state', state?.location)

        if (!state?.location.denied) {
          store.commit('geo/setLocationTrackingPermissionState', {
            hasDeniedPermissions: false,
            isDisabled: false
          })

          $log.debug('Geo: Starting')

          locationTracking.watchId = await geoLocation.watchPosition(
            {
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 10000
            },
            locationTracking.onNewPosition
          )

          store.commit('geo/setState', {
            key: 'locationTracking.isActive',
            value: true
          })
        } else {
          $log.warn('Geo: User has denied location service')

          store.commit('geo/setLocationTrackingPermissionState', {
            hasDeniedPermissions: true
          })
        }
      } catch (error) {
        $log.warn('Geo: User does not have location service enabled', error)

        store.commit('geo/setLocationTrackingPermissionState', {
          isDisabled: true
        })
      }
    }

    locationTracking.stop = async () => {
      if (!locationTracking.watchId) {
        return false
      }

      $log.debug('Geo: Stopping')

      await geoLocation.clearWatch({
        id: locationTracking.watchId
      })

      store.commit('geo/setState', {
        key: 'locationTracking.isActive',
        value: false
      })

      locationTracking.watchId = null
      locationTracking.lastLngLat = []
    }

    locationTracking.onNewPosition = (position, error) => {
      let addToHistory = true

      if (error) {
        $log.warn('Geo: Error with new position', error)
      }

      if (position && position.coords?.latitude) {
        if (
          locationTracking.lastLngLat[0] === position?.coords?.longitude &&
          locationTracking.lastLngLat[1] === position?.coords?.latitude
        ) {
          // console.log('Ignoring duplicate position')
          addToHistory = false
        }

        if (locationTracking.lastLngLat.length > 0) {
          const distanceFromLastPosition = distance(
            locationTracking.lastLngLat,
            [position.coords.longitude, position.coords.latitude],
            {
              units: 'kilometers'
            }
          )

          if (distanceFromLastPosition < 0.01) {
            // console.log('Ignoring position as distance is less than 10m from previous')
            addToHistory = false
          }
        }

        locationTracking.lastLngLat = [position.coords.longitude, position.coords.latitude]

        store.commit('geo/addNewPosition', {
          position: {
            ...position,
            type: locationTypes.USER_LOCATION,
            recordedAt: $date.utc().toISOString(),
            organisationId: store.getters['app/getApp'].currentOrganisation?.id,
            farmId: store.getters['farm/farm']?.id
          },

          addToHistory
        })
      }
    }

    locationTracking.getCurrentPosition = async () => {
      try {
        $log.debug('Geo: Getting current position')

        const position = await geoLocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: Infinity
        })

        return position
      } catch (error) {
        $log.error('Geo: Error getting current position', error)
      }
    }

    locationTracking.removeAllLocalLocations = () => {
      store.commit('geo/clearLocationHistory')
    }
  }

  // Inject into Nuxt context as $locationTracking
  inject('locationTracking', locationTracking)
}
